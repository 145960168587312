import type { ReactNode } from 'react';
import React from 'react';
import type { Coordinate } from '@modules/professional/type/contact-page-types';
import { Label, useStyles } from '@archipro-design/aria';
import * as S from './ClickToReveal.style';
import { useTracker } from '@archipro-website/tracker';
import { useRootData } from '@modules/root/hook/use-root-data';
import type { UseClickToRevealProps } from '../../hook/use-click-to-reveal';
import { useClickToReveal } from '../../hook/use-click-to-reveal';

export type ClickToRevealType = 'Phone' | 'Mobile' | 'Location';

interface Props extends Omit<UseClickToRevealProps, 'revealType'> {
    fulltext: string;
    type: ClickToRevealType;
    visibleLength?: number;
    ellipsis?: string;
    className?: string;
    coordinates?: Coordinate;
    buttonTrackingID: string;
    label: string;
    onRevealed?: () => void;
}

const ClickToReveal: React.FC<Props> = ({
    fulltext,
    type,
    className,
    buttonTrackingID,
    coordinates,
    label,
    revealKey,
    onRevealed,
    revealSubKey,
}: Props) => {
    const { log } = useTracker();
    const { device } = useRootData();

    const showTelLink = type === 'Phone' || type === 'Mobile';

    const ellipsisText = showTelLink ? 'View phone number' : 'View address';

    const telLink = (
        <Label
            as="a"
            variant={'02'}
            href={`tel:${fulltext}`}
            onClick={(e: React.MouseEvent) => {
                e.stopPropagation();
                log(`${type}Submit`, {
                    url: new URL(window.location.href),
                    label: label,
                });
            }}
        >
            {fulltext}
        </Label>
    );

    const showMapLink = type === 'Location' && coordinates?.Latitude;

    const mapHref =
        device.type === 'desktop'
            ? `https://www.google.com/maps/dir/?api=1&destination=${coordinates?.Latitude},${coordinates?.Longitude}&z=9`
            : device.os.isIOS
            ? `http://maps.apple.com/?ll=${coordinates?.Latitude},${coordinates?.Longitude}&q=${fulltext}&z=8`
            : device.os.isAndroid
            ? `geo://0,0?q=${coordinates?.Latitude},${coordinates?.Longitude}&z=8`
            : `https://maps.google.com/?q=${coordinates?.Latitude},${coordinates?.Longitude}&z=8`;

    const locationLink = showMapLink ? (
        <Label as="a" href={mapHref} variant={'02'} target="_blank">
            {fulltext}
        </Label>
    ) : (
        <Label>{fulltext}</Label>
    );

    return (
        <TrackingClickToReveal
            key={fulltext}
            className={`${className ? className : ''}`}
            buttonTrackingID={buttonTrackingID}
            revealKey={revealKey}
            onRevealed={onRevealed}
            revealType={type}
            revealSubKey={revealSubKey}
        >
            {(clicked: boolean) => {
                if (!clicked)
                    return (
                        <Label styles={{ textDecoration: 'underline' }}>
                            {ellipsisText}
                        </Label>
                    );
                return showTelLink ? telLink : locationLink;
            }}
        </TrackingClickToReveal>
    );
};

type RenderFunction = (clicked: boolean) => ReactNode;

interface TrackingClickToRevealProps extends UseClickToRevealProps {
    children: RenderFunction;
    className?: string;
    buttonTrackingID?: string;
    onRevealed?: () => void;
}

const TrackingClickToReveal: React.FC<TrackingClickToRevealProps> = ({
    children,
    buttonTrackingID,
    revealKey,
    onRevealed,
    revealType,
    revealSubKey,
}: TrackingClickToRevealProps) => {
    const { revealed, onRevealClick } = useClickToReveal({
        revealType,
        revealKey,
        revealSubKey,
    });
    const { css } = useStyles({ clicked: revealed });
    return (
        <div
            className={css(S.ClickToRevealContainer)}
            data-button={buttonTrackingID || null}
            onClick={(e: React.MouseEvent) => {
                e.stopPropagation();
                onRevealClick();
                onRevealed?.();
            }}
        >
            {children(revealed)}
        </div>
    );
};

export default ClickToReveal;
